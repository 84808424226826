export const getStorage = (key) => {
  return localStorage.getItem(key);
};

export const setStorage = (key, value) => {
  return localStorage.setItem(key, value);
};

export const removeStorage = (key) => {
  return localStorage.removeItem(key);
};

export const clearUserStrorage = () => {
  try {
    Object.keys(localStorage).forEach((key) => {
      // key不包含 _pageParams 和 _columns 则删除
      if (
        !key.includes('_pageParams') &&
        !key.includes('_columns') &&
        !key.includes('_lastSelection')
      ) {
        localStorage.removeItem(key);
      }
    });
  } catch (e) {
    console.log(e);
  }
};

export const setSession = (key, value) => {
  return sessionStorage.setItem(key, value);
};

export const getSession = (key) => {
  return sessionStorage.getItem(key);
};

export const getAdmin = () => {
  // 69211 赵艳花
  // 000195632 唐晟
  // 14774 范慧颖
  // 145003 黄楠
  // 000245465 陈浩
  // 000244192 郑小帅
  // 000248045 卓
  // 000248043 郭嘉伟
  // 000247426 博
  // 000246624 春
  // 000247605 杰
  // 000207238 金伟
  // 000250443 张昊
  // 000255099 薛友静
  // 000257225 张冉
  // 000254256 孙跃
  // 000266629 李欢欢 - 产品
  return [
    '69211',
    '000195632',
    '14774',
    '145003',
    '000245465',
    '000244192',
    '000248045',
    '000248043',
    '000246624',
    '000247426',
    '000247605',
    '000207238',
    '000250208',
    '000252602', // 大海
    '000252383', // 银川
    // '000250443', // 张昊
    '000255099',
    '000257225',
    '000259322', // 史春兰
    '000254256',
    '000258166', // 李振鹏
    '000253734', // 程海洋
    '000254968', // 王鹏程
    '000266629',
  ].includes(getStorage('userId'));
};

export const removeSession = (key) => {
  return sessionStorage.removeItem(key);
};
