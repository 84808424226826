import { httpDicts } from '@/utils/tools/dict';

// 很多锁
const locks = {};

const region = {};

const Common = {
  state: {
    approval_type: [], // 企微审批类型
    approval_status: [], // 企微申请状态
  },
  reducers: {
    updateState(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    async getDicts(payload = {}, rootState) {
      const { type, ...rest } = payload;
      const dicts = rootState?.financeCenter?.[type];
      if (locks[type] || dicts?.length > 0) return;
      locks[type] = true;
      const res = await httpDicts(type, region[type] || 'welink');
      this.updateState({ [type]: res.pickerOptions });
      locks[type] = false;
    },
  },
};

export default Common;
