import request from '@/utils/request';

/**
 * 省市区
 * @returns  {array}
 */
export const getProviceList = () => {
  return request.get(`/api/v1.0/areas`, {
    params: {
      parentId: 1,
    },
  });
};

/**
 * 省市区
 * @returns  {array}
 */
export const getCityList = (id) => {
  return request.get(`/api/v1.0/areas`, {
    params: {
      parentId: id,
    },
  });
};

/**
 * 省市区
 * @returns  {array}
 */
export const getAreaList = (id) => {
  return request.get(`/api/v1.0/areas`, {
    params: {
      parentId: id,
    },
  });
};

/**
 * 省市区
 * @param levelId {number}  1 - 省市区 2 - 省市  3 - 省
 * @returns  {array}
 */
export const getAreaTree = (levelId = 1) => {
  return request.get(`/api/v1.0/areas/tree`, {
    params: {
      levelId,
    },
  });
};
