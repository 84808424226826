import { getSession, getStorage, setSession } from '@/utils/filter';
import request from '../utils/request';
import { getAppId } from '@/utils/index';
import { WX_BASEURL } from '@/consts/env';
import { getCacheData, setCacheData } from '@/utils/cache';

/**
 * 上传oss的路径
 * @returns
 */
export const uploadFileToOss = `${request.SCRM_BASEURL}/api/v1.0/thunder/upload/file`;

function downloadURL(url, name) {
  const link = document.createElement('a');
  link.download = name;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const downloadFile = (url, fileName = '', requestConfig = {}) => {
  return request
    .get(url, requestConfig)
    .then((resp) => {
      if (resp.status !== 200) {
        throw new Error('Download failed.');
      } else if (resp.blob) {
        return resp.blob();
      } else {
        return new Blob([resp]);
      }
    })
    .then((blob) => URL.createObjectURL(blob))
    .then((aurl) => {
      downloadURL(url, fileName);
      URL.revokeObjectURL(aurl);
    });
};

/** 上传文件到oss */
export const uploadFile = (formData, path = 'formPoster') => {
  return request.post(`${uploadFileToOss}?server=xd-scrm-web&path=${path}`, {
    data: formData,
    // headers:
  });
};

/** 读取远程文件
 * @param {string} url
 */
export const readRemoteFile = (url) => {
  return request.get(url, {
    responseType: 'blob',
  });
};

/** 请求oss里的json文件 */
export const getJsonFile = (url) => {
  return request.get(url);
};

const unique3 = (arr) => {
  const newArray = arr.filter((item, index) => {
    return arr.findIndex((f) => f.id === item.id) === index;
  });
  return newArray;
};

const getPhoneOrderName = (order) => {
  return { 1: '一接', 2: '二接', 3: '三接', 9: '兜底' }[order] || '';
};

/**
 * 查询全量分公司、校区、人员
 * @returns filtered 移除没有人员的分公司 校区，source 全量数据
 */
export const getAllBranchMember = async (isMember) => {
  const cacheKey = 'app-corp-campus_v2';
  const cache = await getCacheData(cacheKey);

  return new Promise((resolve) => {
    if (cache) resolve(JSON.parse(cache.value));
    else
      request.get('/api/v1.0/app/{app-id}/app-corp-campus').then((res) => {
        try {
          // setSession(cacheKey, JSON.stringify(res));
          setCacheData(cacheKey, JSON.stringify(res));
        } catch (e) {
          console.log(e);
        }
        resolve(res);
      });
    // // .get('https://xd-scrm-static.oss-cn-beijing.aliyuncs.com/consts/branchMembers.json')
  }).then((res) => {
    console.log('rest', res);
    const filtered = (res.data || [])
      .map((m) => {
        return {
          ...m,
          label: m.name,
          title: m.name,
          value: m.deptId,
          children: (m.children || [])
            .filter((f) => (f.appMembers || []).length)
            .map((campus) => {
              return {
                ...campus,
                label: campus.name,
                title: campus.name,
                value: campus.deptId,
                children: unique3(campus.appMembers || []).map((member) => {
                  const callPhone = `${member.counselorPhone || ''} ${member.landlinePhone || ''}`;
                  return {
                    ...member,
                    label: `${member.name}  ${getPhoneOrderName(member.phoneOrder)}  ${callPhone}`,
                    title: member.name,
                    value: member.id,
                    callPhone,
                  };
                }),
              };
            }),
        };
      })
      .filter((f) => (f.children || []).length);

    const source = (res.data || []).map((m) => {
      return {
        ...m,
        label: m.name,
        value: m.deptId,
        children: (m.children || []).map((campus) => {
          return {
            ...campus,
            label: campus.name,
            value: campus.deptId,
            children: isMember
              ? unique3(campus.appMembers || []).map((member) => {
                  return {
                    ...member,
                    label: member.name,
                    value: member.id,
                  };
                })
              : null,
          };
        }),
      };
    });

    return {
      source,
      filtered,
    };
  });
};

/**
 * 查询全量分公司、校区、人员[new]
 * @returns
 */
export const getCorpCampusMember = async (isMember) => {
  return request.get('/api/v1.0/app/{app-id}/app-corp-campus');
  // const cacheKey = 'app-corp-campus_v2';
  // const cache = await getCacheData(cacheKey);

  // return new Promise((resolve) => {
  //   if (cache) resolve(JSON.parse(cache.value));
  //   else
  //     request.get('/api/v1.0/app/{app-id}/app-corp-campus').then((res) => {
  //       try {
  //         setCacheData(cacheKey, JSON.stringify(res));
  //       } catch (e) {
  //         console.log(e);
  //       }
  //       resolve(res);
  //     });
  // });
};

// /**
//  * 查询分公司、校区
//  * @returns
//  */
// export const getCorpCampus = () => {
//   return request.get('/api/v1.0/app/{app-id}/app-corp-campus').then((res) => {
//     return res.data.map((m) => {
//       return {
//         ...m,
//         label: m.name,
//         value: m.deptId,
//         children: m.children?.map((child) => {
//           return {
//             ...child,
//             label: child.name,
//             value: child.deptId,
//             isLeaf: false,
//           };
//         }),
//       };
//     });
//   });
// };

const phoneOrderToName = (phoneOrder) => {
  return { 1: '一接', 2: '二接', 3: '三接', 9: '兜底' }[phoneOrder] || '';
};

/**
 * 查询校区下所有人员
 * @param  {number}   deptId      部门id
 * @returns
 */
export const getCampusMembers = (deptId) => {
  return request.get(`/api/v1.0/app/{app-id}/dept/${deptId}/app-members`).then((res) => {
    return (res.data || []).map((m) => {
      const callPhone = m.counselorPhone || m.landlinePhone;
      const label = `${m.name} ${phoneOrderToName(m.phoneOrder)} ${
        m.counselorPhone || m.landlinePhone || ''
      }`;
      return { ...m, label, callPhone, value: m.id };
    });
  });
};

/**
 * 获取枚举数据
 * @param {*} params
 * @returns
 */
export const httpMetaDatas = (params) => {
  let appId = getAppId();
  if (params?.name === 'appId') {
    appId = 1;
  }
  return request.get(`/api/v1.0/app/${appId}/meta-data`, {
    params,
  });
};

/** 根据appmemberId查询所在校区
 * @param {appMemberId} appMemberId
 */
export const httpAppMemberCampus = (appMemberId) => {
  return request.get(`/api/v1.0/app/{app-id}/app-member/${appMemberId}/app-campus`);
};

/**
 * 根据appId查询所有分公司
 * @returns
 */
export const httpAppcorp = () => {
  return request.get(`/api/v1.0/app/{app-id}/app-corp`);
};
/**
 * 根据分公司查询校区
 * @param {id} 分公司id
 * @returns
 */
export const httpSchools = (param) => {
  return request.get(`/api/v1.0/app/{app-id}/${param.id}/app-campus`);
};
/**
 * 根据校区查询校区下所有部门的人员
 * @param {id} 校区id
 * @returns
 */
export const httpAppmembers = (param) => {
  return request.get(`/api/v1.0/app/{app-id}/dept/${param.id}/app-members`);
};

/*
 * 根据appCustomerId查询学员列表
 * @param {appCustomerId} 客户ID
 * @returns
 */
export const httpAppCustomerToStudengts = (param) => {
  return request.get(`/api/v1.0/app/{app-id}/app-student/customer/${param.appCustomerId}`);
};

/**
 * 查询 ppts 各种常量
 * @param {appCustomerId} 客户ID
 * @param {category} 值 常量
 * @returns
 */
export const httPptsConstants = (param) => {
  return request.get(
    `/api/v1.0/app/{app-id}/app-member/${param.appCustomerId}/ppts-constants/category/${param.category}`,
  );
};

/**
 * 根据关键字查询单条appCustomer
 * @param {string} keyword 手机号、线索id、客户id、ps编号
 * @returns {result}
 */
export const onCheckCustomer = (payload) => {
  return request.get(`/api/v1.0/app/${getAppId()}/app-customer/keyword/${payload.keyword}`);
};

/**
 * 根据手机号查询单条appCustomer,不含ppts数据，用于客户线索新增编辑
 * @param {string} keyword 手机号、线索id、客户id、ps编号
 * @returns {result}
 */
export const checkCustomerByPhoneForLJ = (phone) => {
  return request.post(`/api/v1.0/app/{app-id}/app-customer-map`, {
    params: {
      phone,
    },
  });
};

/**
 * 根据手机号查询客户是否已存在(学成含ppts数据)，用于通话弹屏
 * @param {string} phone 手机号
 * @returns {result}
 */
export const checkCustomerByPhone = (phone) => {
  return request.get(`/api/v1.0/app/{app-id}/app-member/fat-customer/${phone}`);
};

// 通过nsNo 查询潜客信息
export const httpPotentialCustomer = (payload) => {
  return request.get(`/api/v1.0/app/{app-id}/potential-customer/ns-no/${payload.nsNo}`);
};

/** 根据oa查询员工信息
 * @param {oa} oa
 * @returns
 */
export const getStaffInfoByOa = (oa) => {
  return request.get(`/api/v1.0/app/{app-id}/app-member/oa-name/job-data`, {
    params: {
      'oa-name': oa,
    },
  });
};

/** 根据oa查询员工信息
 * @param {scene} query 参数
 * @returns page 小程序路径
 */
export const httpMiniQrcode = (params) => {
  const url = `${WX_BASEURL}/api/v1.0/app/{app-id}/wx/ma/user/qrcode`;
  return request.get(url, {
    params,
    responseType: 'arraybuffer',
  });
};

// 获取明细数据-外呼统计
export const httpBehaviorStatic = (region, params) => {
  return request.get(`/api/v1.0/app/{app-id}/region/${region}-count-follow-states`, {
    params,
  });
};

/** 根据appId和appMemberId查询分公司和校区信息
 * @param {appMemberId} appMemberId
 * @returns
 */
export const httpAppMemberInfo = (appMemberId) => {
  return request.post(`/api/v1.0/app/{app-id}/app-corp-campus`, {
    data: [getStorage('appMemberId')],
  });
};
/** 根据appId查询不同业务线下的组织架构
 * @param {payload} 【appId】
 */
export const getAllDepts = (payload) => {
  return request.get(`/api/v1.0/app/${payload.appId}/multi-departments`);
};

/*
 * 查询全量分公司、校区、人员
 */
export const getAllCampus = async (appId) => {
  return new Promise((resolve, reject) => {
    request.get(`/api/v1.0/app/${appId}/app-corp-campus`).then((res) => {
      const source = (res.data || []).map((m) => {
        return {
          ...m,
          label: m.name,
          value: m.deptId,
          children: (m.children || []).map((campus) => {
            return {
              ...campus,
              label: campus.name,
              value: campus.deptId,
              children: [],
              corpId: m.deptId,
              corpName: m.name,
            };
          }),
        };
      });
      resolve(source);
    });
  });
};
/*
 * 查询当前人所在分公司、校区
 */
export const postAllCampus = async (appId, data) => {
  return new Promise((resolve, reject) => {
    request
      .post(`/api/v1.0/app/${appId}/app-corp-campus`, {
        data,
      })
      .then((res) => {
        const sourceData = [];
        data.forEach((item) => {
          const itemData = res?.data?.[item] || [];
          sourceData.push(...itemData);
        });
        const source = (sourceData || []).map((m) => {
          return {
            ...m,
            label: m.name,
            value: m.deptId,
            children: (m.children || []).map((campus) => {
              return {
                ...campus,
                label: campus.name,
                value: campus.deptId,
                children: [],
                corpId: m.deptId,
                corpName: m.name,
              };
            }),
          };
        });
        resolve(source);
      });
  });
};

// 获取商品分类
export const httpGoodsTypes = async () => {
  return request.get(`/api/v1.0/app/{app-id}/goods-types`);
};

/**
 * ocr通用识别
 * @param {type} payload.type  票据类型
 * @param {url} payload.url  图片链接
 */
export const httpOcr = async (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/ocr`, {
    data: payload,
  });
};

/**
 * 根据oa查询用户AppMemberId
 * @param {oa} oa
 */
export const httpAppMemberIdByOa = async (oa) => {
  return request.get(`/api/v1.0/app/{app-id}/app-member/oa-name`, {
    params: {
      'oa-name': oa,
    },
  });
};
