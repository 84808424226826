import { httpDicts, httpGetBankList, httpPayChannels } from '@/utils/tools/dict';

// 很多锁
const locks = {};

const region = {
  asStatus: 'aftersales',
  asType: 'aftersales',
  asRefundReason: 'aftersales',
  compensateReason: 'aftersales',
  refundReason: 'aftersales',
  refundStatus: 'aftersales',
  approvalStatus: 'aftersales',
};

const financeCenter = {
  state: {
    payType: [], // 支付方式
    status: [], // 支付状态
    payChannels: [], // 支付渠道
    bank: [], // 对公主体
    source: [], // 对公主体
    pay_platform: [], // 支付渠道
    asStatus: [], // 售后单状态
    asType: [], // 售后单类型
    asRefundReason: [], // 售后退款原因
    compensateReason: [], // 退费补偿
    refundReason: [], // 退费原因
    refundStatus: [], // 退费状态
    approvalStatus: [], // 审批状态
    refundType: [
      {
        label: '线下打款',
        value: 1,
      },
    ], // 退费方式
  },
  reducers: {
    updateState(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    async getDicts(payload = {}, rootState) {
      const { type, ...rest } = payload;
      const dicts = rootState?.financeCenter?.[type];
      if (locks[type] || (dicts?.length > 0 && type !== 'bank')) return;
      locks[type] = true;
      if (type === 'bank') {
        const res = await httpGetBankList();
        // console.log(res, 'rrr');
        this.updateState({ [type]: res });
        locks[type] = false;
      } else {
        const res = await httpDicts(type, region[type] || 'pay_center');
        this.updateState({ [type]: res.pickerOptions });
        locks[type] = false;
      }
    },
    async getPayChannelsDicts(payload = {}, rootState) {
      const { type, ...rest } = payload;
      if (type === 'payChannels') {
        const res = await httpPayChannels({ ...rest });
        // console.log(res, 'rrr');
        this.updateState({ [type]: res });
        locks[type] = false;
      }
    },
  },
};

export default financeCenter;
