import request from '../utils/request';
import { getAppId } from '@/utils/index';
import { getStorage } from '@/utils/filter';

/**
 * 分页查询标签组
 * @param {object} data
 * @data
 * @param  {number}   ?pageIndex
 * @param  {number}   ?pageSize
 * @returns {array}
 */
export const getTagPage = (data) => {
  return request.post(`/api/v1.0/app/{app-id}/tag`, {
    data: {
      needTotalCount: true,
      ...data,
    },
  });
};

/**
 * 新增标签组
 * @param {object} data
 * @data
 * @param  {string}   title
 * @param  {array[object]} tags
 * @param  {number}   ?appId
 * @param  {number}   ?parentId
 * @param  {string}   ?code
 * @param  {string}   ?style
 * @param  {number}   ?weight
 * @returns {array}
 */

export const saveTag = (params) => {
  const data = {
    appId: getAppId(),
    title: params.title,
    tagDTOs: params.tags.map((tag) => {
      return {
        ...tag,
        appId: getAppId(),
        orderNum: 0
      };
    }),
    orderNum: 0,
    deptDTOs: params?.deptDTOs
  };

  return request.post(`/api/v1.0/app/{app-id}/tags`, {
    data,
  });
};

/**
 * 修改标签
 * @param params
 */
export const updateTag = (params) => {
  const data = {
    appId: getAppId(),
    title: params.title,
    tagDTOs: params.tags.map((tag) => {
      return {
        ...tag,
        appId: getAppId(),
        orderNum: 0
      };
    }),
    orderNum: 0,
    deptDTOs: params?.deptDTOs
  };

  return request.put(`/api/v1.0/app/{app-id}/tag/${params.id}`, {
    data,
  });
};

/**
 * 获取适用范围内的标签
 */
export const getDepartmentTag = () => {
  return request.get(`/api/v1.0/app/{app-id}/member-tag/list`)
};

/**
 * 删除标签
 * @param  {number}   id    标签id
 * @returns {result}
 */

export const deleteTag = (id) => {
  return request.del(`/api/v1.0/app/{app-id}/tag/${id}`);
};

/**
 * 查询渠道列表
 * @param  {number}   id
 * @returns {array}
 */
export const getChannelList = (id = 0) => {
  return request.get(`/api/v1.0/app/{app-id}/channel/${id}`);
};

/**
 * 增加渠道
 * @param  {array.object}   data
 * @returns {array}
 */
export const saveChannel = (data) => {
  return request.post(`/api/v1.0/app/{app-id}/channels`, {
    // data: { ...data, appId: getAppId() },
    data: data.map((m) => {
      return {
        ...m,
        appId: getAppId(),
      };
    }),
  });
};

/**
 * 删除渠道
 * @param  {number}   id    渠道id
 * @returns {result}
 */
export const deleteChannel = (id) => {
  return request.del(`/api/v1.0/app/{app-id}/channel/${id}`);
};

// /**
//  * 外呼管理-获取分机信息
//  * @param  {string}   ?type     all全部，other未分配
//  * @returns {array}
//  */
// export const getCallerList = (type = 'all') => {
//   return request.get(`/api/v1.0/app/{app-id}/call-platform/1/callers/${type}`);
// };

/**
 * 分页查询全部校区
 * @param {object} data
 * @data
 * @param  {number}   ?pageIndex
 * @param  {number}   ?pageSize
 * @returns {array}
 */
export const getCampusPage = (data) => {
  return request.post(`/api/v1.0/app/{app-id}/app-company/list`, {
    data: {
      needTotalCount: true,
      ...data,
    },
  });
};

/**
 * 更新校区对外校区名称和地址
 * @param {object} data
 * @data
 * @param  {number}   id
 * @param  {string}   show_name
 * @param  {string}   address
 * @returns {array}
 */
export const updateCampus = (data) => {
  return request.put(`/api/v1.0/app/{app-id}/app-company-address`, {
    data,
  });
};

/**
 * 刷新组织架构人员接口缓存
 * @returns {array}
 */
export const refreshOrgCache = () => {
  const appMemberId = getStorage('appMemberId');
  return request.del(
    `/api/v1.0/app/{app-id}/app-member/${appMemberId}/multi-department/cache/refresh`,
  );
};


/**
 * 获取分公司的渠道修改时间配置
 * @param {Object} payload
 * @param {Array} payload.corpIds 分公司id
 */
export const getBranchChannelConfig = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/list-corp-channel-regulation`, {
    data: {
      ...payload,
    },
  });
};

/**
 * 编辑渠道修改时间
 * @param {Object} payload
 * @param {Number} payload.corpId 分公司id
 * @param {Number} payload.modifyLimitTime 限制修改时间（天）
 */
export const updateBranchChannelConfig = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/modify-corp-channel-regulation`, {
    data: {
      ...payload,
    },
  });
};

/**
 * 添加档案类型
 * @param {*} params
 * @returns
 */
export const httpAddFile = (params) => {
  return request.post(`/api/v1.0/app/{app-id}/profile-setting`, {
    data: {
      ...params,
    },
  });
};

/**
 * 档案类型列表
 * @param {*} params
 * @returns
 */
export const httpFileList = (params) => {
  return request.post(`/api/v1.0/app/{app-id}/profile-setting/page`, {
    data: {
      ...params,
    },
  });
};

/**
 * 档案类型设置状态
 * @returns
 */
export const httpSettingStatus = (params) => {
  return request.put(`/api/v1.0/app/{app-id}/profile-setting-status`, {
    data: {
      ...params,
    },
  });
};

/**
 * 档案删除
 * @param {*} id
 * @returns
 */
export const httpDeleteFile = (id) => {
  return request.del(`/api/v1.0/app/{app-id}/profile-setting/${id}`);
};

/**
 * 档案类型排序
 * @param {*} params
 * @returns
 */
export const httpSettingSort = (params) => {
  return request.put(`/api/v1.0/app/{app-id}/profile-setting/sort`, {
    data: {
      ...params,
    },
  });
};

/**
 * 获取数据分配规则列表
 * @param {Object} payload
 * @param {Number} payload.pageIndex
 * @param {Number} payload.pageSize
 * @param {Number} payload.id 规则id
 * @param {String} payload.name 规则名称
 * @param {Array} payload.leadsDestinations 数据流向
 * @param {Array} payload.channelIds 一级渠道id
 * @param {Array} payload.channelId2s 二级渠道id
 * @param {Number} payload.status 状态
 * @param {Array} payload.applyDeptIds 适用范围部门id
 * @param {Boolean} payload.applyScope
 */
export const getAllocateRuleList = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/leads-allot-rule/page`, {
    data: {
      ...payload,
    },
  });
};

/**
 * 启用/禁用分配规则
 * @param {Object} payload
 * @param {Number} payload.id 规则id
 * @param {Number} payload.status 状态
 */
export const changeAllocateRuleStatus = (payload) => {
  return request.put(`/api/v1.0/app/{app-id}/leads-allot-rule/status`, {
    data: {
      ...payload,
    },
  });
};

/**
 * 删除分配规则
 * @param {Number} id 规则id
 */
export const delAllocateRuleList = (id) => {
  return request.del(`/api/v1.0/app/{app-id}/leads-allot-rule/${id}`);
};

/**
 * 新增分配规则
 * @param {Object} payload
 * @param {String} payload.name 分配规则名称
 * @param {Number} payload.channelId 一级渠道id
 * @param {String} payload.channelName 一级渠道名称
 * @param {Number} payload.channelId2 二级渠道id
 * @param {String} payload.channelName2 二级渠道名称
 * @param {Number} payload.leadsDestination 数据流向
 * @param {Number} payload.allotTimeModel 分配模式
 * @param {Number} payload.standbyMemberId 备选员工id
 * @param {String} payload.standbyMemberName 备选员工姓名
 * @param {String} payload.standbyMemberOacode 备选员工OA
 * @param {Array} payload.allotTimes 分配规则
 * @param {Number} payload.allotTimes[0].weekDay 周
 * @param {String} payload.allotTimes[0].startTime 开始时间
 * @param {String} payload.allotTimes[0].endTime 结束时间
 * @param {Array} payload.allotTimes[0].applyMembers 员工
 * @param {Number} payload.allotTimes[0].applyMembers[0].appMemberId 员工id
 * @param {String} payload.allotTimes[0].applyMembers[0].appMemberName 员工姓名
 * @param {String} payload.allotTimes[0].applyMembers[0].appMemberOaCode 员工OA
 * @param {Number} payload.allotTimes[0].applyMembers[0].leadsCountLimit 单轮分配上限
 * @param {Number} payload.allotTimes[0].applyMembers[0].sort 顺序
 * @param {Array} payload.applyDepts 适用范围
 * @param {Number} payload.applyDepts[0].deptId 部门id
 * @param {String} payload.applyDepts[0].deptName 部门名称
 * @param {Array} payload.applyDepts[0].children
 * @param {Number} payload.applyDepts[0].children[0].deptId
 * @param {String} payload.applyDepts[0].children[0].deptName
 */
export const addAllocateRule = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/leads-allot-rule`, {
    data: {
      ...payload,
    },
  });
};

/**
 * 分配规则详情
 * @param {Number} id 规则id
 */
export const getAllocateRuleDetail = (id) => {
  return request.get(`/api/v1.0/app/{app-id}/leads-allot-rule/detail/${id}`);
};

/**
 * 编辑分配规则
 * @param {Object} payload
 * @param {Number} payload.id 规则id
 * @param {String} payload.name 分配规则名称
 * @param {Number} payload.channelId 一级渠道id
 * @param {String} payload.channelName 一级渠道名称
 * @param {Number} payload.channelId2 二级渠道id
 * @param {String} payload.channelName2 二级渠道名称
 * @param {Number} payload.leadsDestination 数据流向
 * @param {Number} payload.allotTimeModel 分配模式
 * @param {Number} payload.standbyMemberId 备选员工id
 * @param {String} payload.standbyMemberName 备选员工姓名
 * @param {String} payload.standbyMemberOacode 备选员工OA
 * @param {Array} payload.allotTimes 分配规则
 * @param {Number} payload.allotTimes[0].weekDay 周
 * @param {String} payload.allotTimes[0].startTime 开始时间
 * @param {String} payload.allotTimes[0].endTime 结束时间
 * @param {Array} payload.allotTimes[0].applyMembers 员工
 * @param {Number} payload.allotTimes[0].applyMembers[0].appMemberId 员工id
 * @param {String} payload.allotTimes[0].applyMembers[0].appMemberName 员工姓名
 * @param {String} payload.allotTimes[0].applyMembers[0].appMemberOaCode 员工OA
 * @param {Number} payload.allotTimes[0].applyMembers[0].leadsCountLimit 单轮分配上限
 * @param {Number} payload.allotTimes[0].applyMembers[0].sort 顺序
 * @param {Array} payload.applyDepts 适用范围
 * @param {Number} payload.applyDepts[0].deptId 部门id
 * @param {String} payload.applyDepts[0].deptName 部门名称
 * @param {Array} payload.applyDepts[0].children
 * @param {Number} payload.applyDepts[0].children[0].deptId
 * @param {String} payload.applyDepts[0].children[0].deptName
 */
export const updateAllocateRule = (payload) => {
  return request.put(`/api/v1.0/app/{app-id}/leads-allot-rule`, {
    data: {
      ...payload,
    },
  });
};

/**
 * 查询分公司跟进类型
 * @param {*} params
 */
export const httpFollowTypeList = (params) => {
  return request.post(`/api/v1.0/app/{app-id}/resource-master/page-corp-follow-type`, {
    data: {
      ...params
    }
  })
}

/**
 * 新增跟进类型
 * @param {*} params
 */
export const httpAddFollowType = (params) => {
  return request.post(`/api/v1.0/app/{app-id}/resource-master/save-corp-follow-type`, {
    data: {
      ...params
    }
  })
}

/**
 * 档案类型设置状态
 * @param {*} params
 */
export const httpSettingFollowStatus = (params) => {
  return request.post(`/api/v1.0/app/{app-id}/resource-master/edit-corp-follow-type-status`, {
    data: {
      ...params
    }
  })
}

/**
 * 跟进类型删除
 * @param {*} id
 */
export const httpDeleteFollowType = (id) => {
  return request.post(`/api/v1.0/app/{app-id}/resource-master/delete-corp-follow-type`, {
    data: {
      id
    }
  })
}

/**
 * 跟进类型排序
 * @param {*} params
 */
export const httpFollowTypeSort = (params) => {
  return request.post(`/api/v1.0/app/{app-id}/resource-master/reorder-corp-follow-type`, {
    data: {
      ...params
    }
  })
}

/**
 * 获取列表归属范围配置
 * @param {Object} payload
 * @param {Number} payload.pageIndex
 * @param {Number} payload.pageSize
 * @param {Number} payload.listId 列表名称
 * @param {Number} payload.roleId 角色
 * @param {Number} payload.visibleAttributionId 可见归属
 */
export const getListScopeConfig = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/role-visible-attribution/page`, {
    data: {
      ...payload,
    },
  });
};

/**
 * 新增列表归属范围
 * @param {Object} payload
 * @param {Number} payload.roleId 角色id
 * @param {Number} payload.listId 列表id
 * @param {String} payload.listName 列表名称
 * @param {Array} payload.visibleAttribution 可见归属范围
 * @param {Number} payload.visibleAttribution[0].id
 * @param {String} payload.visibleAttribution[0].name
 * @param {String} payload.visibleAttribution[0].key
 */
export const addListScopeConfig = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/role-visible-attribution`, {
    data: {
      ...payload,
    },
  });
};

/**
 * 编辑列表归属范围
 * @param {Number} id
 * @param {Object} payload
 * @param {Array} payload.visibleAttribution 可见归属范围
 * @param {Number} payload.visibleAttribution[0].id
 * @param {String} payload.visibleAttribution[0].name
 * @param {String} payload.visibleAttribution[0].key
 */
export const updateListScopeConfig = (id, payload) => {
  return request.put(`/api/v1.0/app/{app-id}/role-visible-attribution/${id}`, {
    data: {
      ...payload,
    },
  });
};

export default {
  getTagPage,
  saveTag,
};
